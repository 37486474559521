import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import Pricings from '../../components/home/v3/Pricings';
import PricingFooter from '../../components/home/v3/PricingFooter';
import PricingsModal from '../../components/home/v2/PricingsModal';
import PricingCategoriesSelector from '../../components/home/v3/PricingCategoriesSelector';
import { Container as ContainerV3, Content } from '../../components/home/v3/styledComponents';
import Layout from '../../layouts/index';
import pricings from '../../services/pricing';

const Container = styled(ContainerV3)`
  padding-top: 0px !important;
`;

class PricingsPageWebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllPricings: props.isOpen,
      showMobilePricingCategoriesSelector: false,
      selectedCategory: 'cloth',
      selectedSubCategory: 'pant',
    };
    this.selectCategory = this.selectCategory.bind(this);
  }

  componentDidUpdate(_, prevState) {
    const { context: { isMobile } } = this.props;
    const { showMobilePricingCategoriesSelector, selectedSubCategory, showAllPricings } = this.state;
    if (isMobile
      && ((showMobilePricingCategoriesSelector
      && showMobilePricingCategoriesSelector !== prevState.showMobilePricingCategoriesSelector)
      || (selectedSubCategory !== prevState.selectedSubCategory)
      || (showAllPricings !== prevState.showAllPricings))) {
      window.scrollTo(0, 0);
    }
  }

  selectCategory(slug) {
    const { selectedCategory } = this.state;
    this.setState({ selectedCategory: slug === selectedCategory ? '' : slug });
  }

  render() {
    const { showAllPricings, showMobilePricingCategoriesSelector, selectedCategory, selectedSubCategory } = this.state;
    if (showMobilePricingCategoriesSelector) {
      return (
        <Layout routeSlug="PricingsPageWebView">
          <Container>
            <Content>
              <PricingCategoriesSelector
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                selectCategory={this.selectCategory}
                selectSubCategory={subCategory => (
                  this.setState({ selectedSubCategory: subCategory, showMobilePricingCategoriesSelector: false })
                )}
                hideMobilePricingCategoriesSelector={() => (
                  this.setState({ showMobilePricingCategoriesSelector: false })
                )}
              />
            </Content>
          </Container>
        </Layout>
      );
    }
    return (
      <Layout routeSlug="PricingsPageWebView">
        {showAllPricings ?
          <PricingsModal
            pricings={pricings}
            close={() => this.setState({ showAllPricings: false })}
          /> :
          <Container>
            <Content>
              <Pricings
                isWebView
                openAllPricings={() => this.setState({ showAllPricings: true })}
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                selectCategory={this.selectCategory}
                selectSubCategory={subCategory => this.setState({ selectedSubCategory: subCategory })}
                showMobilePricingCategoriesSelector={() => this.setState({ showMobilePricingCategoriesSelector: true })}
              />
            </Content>
            <Content>
              <PricingFooter isWebView openAllPricings={() => this.setState({ showAllPricings: true })} />
            </Content>
          </Container>
        }
      </Layout>
    );
  }
}

PricingsPageWebView.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  isOpen: PropTypes.bool,
};

PricingsPageWebView.defaultProps = {
  isOpen: false,
};

export default withAppContext(PricingsPageWebView);
